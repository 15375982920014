import './Styling/Speakers.css'
import DebojyotiDebroy from './Images/Debojyoti Debroy.jpg';
import BuddhavrataChakravorty from './Images/Buddabrata Chakravorty.jpg';
import patil from './Images/patil.jpg';
import supreth from './Images/supreth.jpg';
import mantosh from './Images/mantosh.jpg';
import vijay from './Images/vijay.jpg';
import manikandan from './Images/manikandan.jpg';
import ved from './Images/ved.jpg';

function Speakers(){
    
    const speakerdata =[
    {
        name:'Dr. Manikandan.G',
        designation:'Principal Scientist, Product Application Research Group, Centre for innovation in Mobility Research & Development, Tata Steel',
        image:  manikandan
        
    },    
    {
        name:'Mr. Buddhabrata Chakravorty',
        designation:'Co-founder  @ SWAPP Design, He has 14 years of experience in the automotive industry, specializing in autonomous mobility and safety, vehicle dynamics, and powertrain technology.',
        image:  BuddhavrataChakravorty
        
    },
    {
        name:'Mr. Mantosh Kumar',
        designation:'General Manager @ Continental Automotive, Overall 24+ Years in the Automotive Industry. Worked for many car OEMs like VW, BMW, Toyota, Suzuki, and Nissan.',
        image: mantosh
        
    },
    {
        name:'Mr. Supreeth Chandrashekar',
        designation:'Lead ePowertrain applications & Customer Engineering initiatives, Accomplished Engineering with 19 years of extensive domestic and international experience in the automotive industry, with a proven track record in product design, development, and application.',
        image: supreth
    },
    {
        name:'Mr. Vijay Shankar',
        designation:'Principal Engineer, Materials Technology, Mahindra Research Valley, Mahindra & Mahindra Ltd., Chennai. His distinguished career includes roles at prominent companies such as Rane Group, Delphi TVS, Tube Investments of India, and Mahindra & Mahindra Ltd.',
        image: vijay
    },
    {
        name:'Mr. Sanjay Patil',
        designation:'General Manager at Automotive Research Association of India (ARAI) Training with Automotive Purpose.',
        image: patil
    },
    {
        name:'VED VRAT NUCLEAR',
        designation:'SR. PROJECT MANAGER. ZF TCI, HYDERABAD',
        image: ved
    }]


    return (
        <>  

            <div className='speakerheading' id='speakers'> Speakers </div>
            
            <div className="speakers">
                {speakerdata.map( speaker =>
                <div className="speakerbox">
                    <div className="speaker-image">
                    <img src={speaker.image}></img>
                    </div>
                    <div className="speaker-name">
                        {speaker.name}
                    </div>
                    <div className="speaker-designation">
                        {speaker.designation}
                    </div>
                </div>
                )}
                
            </div>
            {/* <div className='collaborator'>
            <div className='speakerbox'>
            <div className='colabhead'> Collaborator</div>
                <div className="speaker-image">
                    <img src={collab}></img>
                    </div>
                    <div className="speaker-name">
                        Mr. Rajesh Kumar A.
                    </div>
                    <div className="speaker-designation">
                    CEO @ AIC T-Hub

                    </div>
            </div>
            </div> */}
        
        </>
    )
}

export default Speakers;