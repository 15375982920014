import { useState } from "react"
import autownlogo from './Images/Autown logo.png'
import './Styling/Registeracs.css';
import formicon from './Images/formicon.png';
//import { useNavigate } from "react-router-dom";
function Registeradas() {


    const projects = [
        "Adaptive Cruise Control: Real-Time Speed Adjustment for Traffic Flow",
        "Maze Navigator: Autonomous Route Optimization in Complex Environments",
        "Voice-Activated Car Control: Seamless Hands-Free Driving Experience",
        "Predictive Vehicle Health: AI-Driven Maintenance Alerts",
        "Smart License Plate Recognition: Efficient Tolling and Parking Solutions",
        "Obstacle-Aware Autonomous Navigation: Smart Car Pathfinding",
        "Multi-Sensor Collision Avoidance: Integrating Radar, LiDAR, and Cameras for Enhanced Safety",
        "Vehicle Localization and Mapping: Combining GPS, IMU, LiDAR, and Camera Inputs for Precise Positioning",
        "Troubleshooting Assistance Using AI Chatbot",
        "Automated Fleet Scheduling",
        "Others"
    ]


    const [formInput, updateFormInput] = useState({
        name: "",
        num: "",
        bitsemail: "",
        bitsid: "",
        participants: "",
        registerfor: "",
        proj: ""
    })

    let form = document.querySelector("form");
    //const navigate = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault();
        //window.open('https://payu.in/invoice/CFB22C358D633C58D99D24440459571F7E7188F585220534625FAFB9C5BA7A91/9DC80EEF8045B304C97797B78C9F305B','_blank', 'rel=noopener noreferrer')
        // navigate("https://payu.in/invoice/CFB22C358D633C58D99D24440459571F7E7188F585220534625FAFB9C5BA7A91/9DC80EEF8045B304C97797B78C9F305B")

        let data = new FormData(form);
        fetch('https://script.google.com/macros/s/AKfycbwUYjcYaVnu0apndTd3li2F_3ZxjNiPDXTWO5fWqrcxQSYmnhuhZAageUVvAznGFLl0zA/exec', {
           method: "POST",
             body: data
         })
            .then(res => res.text())
            .then(window.location.href = '/')
            .then(alert("Thanks for registering.  A confirmation email along with the payment link for registration fees of Rs 500 will be sent to your registered email soon."))
    }

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        updateFormInput({ ...formInput, [name]: value })
    }


    return (
        <div className="mainbg">
            <div className="Regadas" id='regadas'>
                <div className="title">
                    <a href="/"><img src={autownlogo} className="autownlogo"></img></a>
                    <span className="head">Registration</span>
                </div>
                <h1 className="heading">Advanced Driver-Assistance System</h1>
                <div className='regform' >

                    <form onSubmit={handleSubmit}>
                        <div className="form">
                            <div className="subform" > <label htmlFor='name'>Name</label>
                                <input className='inparea1' autoComplete='off' name='name' id='name' required="true"
                                    value={formInput.name}
                                    onChange={handleInput}
                                    type='text' placeholder='Name'></input>

                                <label htmlFor='num'>Contact Number</label>
                                <input className='inparea1' autoComplete='off' name='num' id='num' required="true"
                                    value={formInput.num}
                                    onChange={handleInput}
                                    type='text' placeholder='Number'></input>


                                <label htmlFor='email'>Email</label>
                                <input className='inparea1' autoComplete='off' name='bitsemail' id='bitsemail' required="true"
                                    value={formInput.bitsemail}
                                    onChange={handleInput}
                                    type='email' placeholder='Email' ></input>


                                <label htmlFor='bitsid'>Name of Institute, State</label>
                                <input className='inparea1' autoComplete='off' name='bitsid' id='bitsid' required="true"
                                    value={formInput.bitsid}
                                    onChange={handleInput}
                                    type='text' placeholder='Name of the Institute' ></input>


                                <label htmlFor='No. of Participants'>No. of Participants</label>
                                {/* <input className='inparea1' autoComplete='off' name='participants' id='participants' required="true"
                                    value={formInput.participants}
                                    onChange={handleInput}
                                    type='text' placeholder='No. of Participants' ></input> */}
                                <select className='inparea1' autoComplete='off' name='participants' id='participants' required="true"
                                    value={formInput.participants}
                                    onChange={handleInput}>
                                    <option value="">Select No. Of Partcipants</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </select>
                                <label htmlFor='Register For'>Register For</label>
                                <select className='inparea1' autoComplete='off' name='registerfor' id='registerfor' required="true"
                                    value={formInput.registerfor}
                                    onChange={handleInput}>
                                    <option value="Project">Project</option>
                                    <option value="Poster">Poster</option>
                                </select>
                            </div>
                            <div >
                                <img src={formicon}></img>
                            </div>
                        </div>
                        <div className="project">
                            <h3 className="projarea projheading">Select Project</h3>
                            {projects.map((project) => (
                                <>
                                    <input type="radio" name="proj" className="projarea" value={project} ></input>
                                    <label htmlFor="projinput">{project}</label><br></br><br></br><br></br></>)
                            )}
                        </div>

                        <input className='subarea' type="submit" value="Submit"></input>

                        <input type="hidden" name="formType" value="adas" />

                    </form>
                </div>
            </div>
        </div>
    )
}

export default Registeradas;