import { useState} from "react";
//import { useNavigate } from "react-router-dom";
import autownlogo from './Images/Autown logo.png'
import './Styling/Registeracs.css';
import formicon from './Images/formicon.png'
function Registeracs() {

    const projects = [
        'Materials Technology for Electric Vehicles (EVs)',
        'Advanced Materials for Durability, Safety and Sustainability to Improve Performance of Automotive Vehicles',
        'Others',
        // '"Cruise Control Innovation Challenge" - Advancing Autonomous Driving with Next-Gen Cruise Control Systems',
        //'"Virtual Vehicle Diagnostics Challenge" - Revolutionizing Automotive Troubleshooting and Maintenance',
        //'"DIY OBD-II Code Scanner Challenge" - Crafting Your Own Diagnostic Tool with Arduino or Raspberry Pi',
        //'"Custom Vehicle Dashboard Display Challenge" - Crafting Your Own Real-Time Data Display with Arduino or Raspberry Pi',
        //'"Fault Diagnosis Simulator Challenge" - Advancing Automotive Troubleshooting with Virtual Fault Scenarios',
        //'"Diagnostic Decision Support System Challenge" - Innovating Automotive Troubleshooting with AI-Powered Solutions'
    ]


    const [formInput, updateFormInput] = useState({
        acsname: "",
        acsnum: "",
        acsbitsemail: "",
        acsbitsid: "",
        acsparticipants: "",
        acsregister: "",
        acsproj: ""
    })

    let form = document.querySelector("form");
    //const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        //navigate("https://payu.in/invoice/CFB22C358D633C58D99D24440459571F7E7188F585220534625FAFB9C5BA7A91/9DC80EEF8045B304C97797B78C9F305B")
        let data = new FormData(form);
        fetch('https://script.google.com/macros/s/AKfycbwUYjcYaVnu0apndTd3li2F_3ZxjNiPDXTWO5fWqrcxQSYmnhuhZAageUVvAznGFLl0zA/exec', {
            method: "POST",
            body: data
        })
           .then(res => res.text())
           .then(window.location.href = '/')
           .then(alert("Thanks for registering.  A confirmation email along with the payment link for registration fees of Rs 500 will be sent to your registered email soon."))}

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        updateFormInput({ ...formInput, [name]: value })
    }


    return (
        <div className="mainbg">
            <div className="Regacs" id='regacs'>
                <div className="title">
                    <a href="/"><img src={autownlogo} className="autownlogo"></img></a>
                    <span className="head">Registration</span>
                </div>
                <h1 className="heading">Material Science</h1>
                <div className='regform' >

                    <form onSubmit={handleSubmit}>
                        <div className="form">
                            <div className="subform" >
                                <label htmlFor='name'>Name</label>
                                <input className='inparea1' autoComplete='off' name='acsname' id='acsname' required="true"
                                    value={formInput.acsname}
                                    onChange={handleInput}
                                    type='text' placeholder='Name'></input>
                                
                                <label htmlFor='num'>Contact Number</label>
                                <input className='inparea1' autoComplete='off' name='acsnum' id='acsnum' required="true"
                                    value={formInput.acsnum}
                                    onChange={handleInput}
                                    type='text' placeholder='Number'></input>


                                <label htmlFor='email'>Email</label>
                                <input className='inparea1' autoComplete='off' name='acsbitsemail' id='acsbitsemail' required="true"
                                    value={formInput.acsbitsemail}
                                    onChange={handleInput}
                                    type='email' placeholder='Email' ></input>


                                <label htmlFor='bitsid'>Name of the Institute, State</label>
                                <input className='inparea1' autoComplete='off' name='acsbitsid' id='acsbitsid' required="true"
                                    value={formInput.acsbitsid} 
                                    onChange={handleInput}
                                    type='text' placeholder='Name of the Institute' ></input>


                                {/* <label htmlFor='No. of Participants'>No. of Participants</label>
                                <input className='inparea1' autoComplete='off' name='acsparticipants' id='acsparticipants' required="true"
                                    value={formInput.acsparticipants}
                                    onChange={handleInput}
                                    type='text' placeholder='No. of Participants' ></input> */}

                                <label htmlFor='No. of Participants'>No. of Participants</label>
                                <select className='inparea1' autoComplete='off' name='acsparticipants' id='acsparticipants' required="true"
                                    value={formInput.acsparticipants}
                                    onChange={handleInput}>
                                    <option value="">Select No. Of Partcipants</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </select>

                                <label htmlFor='Register For'>Register For</label>
                                <select className='inparea1' autoComplete='off' name='acsregister' id='acsregister' required="true"
                                    value={formInput.acsregister}
                                    onChange={handleInput}>
                                    <option value="Project">Project</option>
                                    <option value="Poster">Poster</option>
                                </select>
                            </div>
                            <div >
                                <img src={formicon}></img>
                            </div>
                        </div>
                        <div className="project">
                            <h3 className="projarea projheading">Select Project</h3>
                            {projects.map((project) => (
                                <>
                                    <input type="radio" name="acsproj" className="projarea" value={project} ></input>
                                    <label htmlFor="projinput">{project}</label><br></br><br></br><br></br></>)
                            )}
                        </div>

                        <input className='subarea' type="submit" value="Submit"></input>

                        <input type="hidden" name="formType" value="ms" />

                    </form>
                </div>
            </div>
        </div>
    )
}

export default Registeracs;