import { useState } from "react"
import autownlogo from './Images/Autown logo.png'
import formicon from './Images/formicon.png'
import './Styling/Registeracs.css';
import { useNavigate } from "react-router-dom";
function Registerev() {

    const projects = [
        "Sensor data logging for EVs",
        "Battery SOC estimation",
        "Multisource charging stations",
        "Electric vehicle skateboard",
        "Regenerative braking demo",
        "Others"
    ]


    const [formInput, updateFormInput] = useState({
        evname: "",
        evnum: "",
        evbitsemail: "",
        evbitsid: "",
        evparticipants: "",
        evregister: "",
        evproj: ""
    })

    let form = document.querySelector("form");
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        // window.open('https://payu.in/invoice/CFB22C358D633C58D99D24440459571F7E7188F585220534625FAFB9C5BA7A91/9DC80EEF8045B304C97797B78C9F305B','_blank', 'rel=noopener noreferrer')

        // navigate("https://payu.in/invoice/CFB22C358D633C58D99D24440459571F7E7188F585220534625FAFB9C5BA7A91/9DC80EEF8045B304C97797B78C9F305B")

        let data = new FormData(form);
        fetch('https://script.google.com/macros/s/AKfycbwUYjcYaVnu0apndTd3li2F_3ZxjNiPDXTWO5fWqrcxQSYmnhuhZAageUVvAznGFLl0zA/exec', {
            method: "POST",
            body: data
        })
            .then(res => res.text())
            .then(window.location.href = '/')
            .then(alert("Thanks for registering.  A confirmation email along with the payment link for registration fees of Rs 500 will be sent to your registered email soon."))
    }

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        updateFormInput({ ...formInput, [name]: value })
    }


    return (
        <div className="mainbg">
            <div className="Regev" id='regev'>
                <div className="title">
                    <a href="/"><img src={autownlogo} className="Autown logo"></img></a>
                    <span className="head">Registration</span>
                </div>
                <h1 className="heading">Electric Vehicle</h1>
                <div className='regform' >

                    <form onSubmit={handleSubmit}>
                        <div className="form">
                            <div className="subform" ><label htmlFor='name'>Name</label>
                                <input className='inparea1' autoComplete='off' name='evname' id='evname' required="true"
                                    value={formInput.evname}
                                    onChange={handleInput}
                                    type='text' placeholder='Name'></input>

<label htmlFor='num'>Contact Number</label>
                                <input className='inparea1' autoComplete='off' name='evnum' id='evnum' required="true"
                                    value={formInput.evnum}
                                    onChange={handleInput}
                                    type='text' placeholder='Number'></input>


                                <label htmlFor='email'>Email</label>
                                <input className='inparea1' autoComplete='off' name='evbitsemail' id='evbitsemail' required="true"
                                    value={formInput.evbitsemail}
                                    onChange={handleInput}
                                    type='email' placeholder='Email' ></input>


                                <label htmlFor='bitsid'>Name of Institute, State</label>
                                <input className='inparea1' autoComplete='off' name='evbitsid' id='evbitsid' required="true"
                                    value={formInput.evbitsid}
                                    onChange={handleInput}
                                    type='text' placeholder='Name of the Institute' ></input>


                                <label htmlFor='No. of Participants'>No. of Participants</label>
                                {/* <input className='inparea1' autoComplete='off' name='evparticipants' id='evparticipants' required="true"
                                    value={formInput.evparticipants}
                                    onChange={handleInput}
                                    type='text' placeholder='No. of Participants' ></input> */}
                                <select className='inparea1' autoComplete='off' name='evparticipants' id='evparticipants' required="true"
                                    value={formInput.evparticipants}
                                    onChange={handleInput}>
                                    <option value="">Select No. Of Partcipants</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </select>

                                <label htmlFor='Register For'>Register For</label>
                                <select className='inparea1' autoComplete='off' name='evregister' id='evregister' required="true"
                                    value={formInput.evregister}
                                    onChange={handleInput}>
                                    <option value="Project">Project</option>
                                    <option value="Poster">Poster</option>
                                </select>
                            </div>
                            <div >
                                <img src={formicon}></img>
                            </div>
                        </div>
                        <div className="project">
                            <h3 className="projarea projheading">Select Project</h3>
                            {projects.map((project) => (
                                <>
                                    <input type="radio" name="evproj" className="projarea" value={project} ></input>
                                    <label htmlFor="projinput">{project}</label><br></br><br></br><br></br></>)
                            )}
                        </div>

                        <input className='subarea' type="submit" value="Submit"></input>

                        <input type="hidden" name="formType" value="ev" />

                    </form>
                </div>
            </div>
        </div>
    )
}

export default Registerev;